import Constant from './constant';

export const generateDefaultSEO = (meta = Constant.META.WP) => {
  const {
    TITLE = Constant.META.WP.TITLE,
    DESCRIPTION = Constant.META.WP.DESCRIPTION,
    URL = Constant.META.WP.URL,
    AMP_URL,
  } = meta;

  return {
    TITLE,
    DESCRIPTION,
    CONTENT: DESCRIPTION,
    URL,
    AMP_URL,
    BASE_LD_JSON: {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: TITLE,
      description: DESCRIPTION,
      url: URL,
      sameAs: [
        Constant.SOCIAL_MEDIA.TWITTER_BEAUTY_JOURNAL,
        Constant.SOCIAL_MEDIA.FACEBOOK_BEAUTY_JOURNAL,
        Constant.SOCIAL_MEDIA.INSTAGRAM_BEAUTY_JOURNAL,
        Constant.SOCIAL_MEDIA.YOUTUBE_BEAUTY_JOURNAL,
        Constant.SOCIAL_MEDIA.LINE_BEAUTY_JOURNAL,
      ],
    },
  };
};

export const defaultSeo = {
  ...generateDefaultSEO(),
  BASE_LD_JSON: {
    ...generateDefaultSEO().BASE_LD_JSON,
    '@type': 'WebSite',
    potentialAction: {
      '@type': 'SearchAction',
      target: `${Constant.META.WP.URL}/search/{search_term}`,
      'query-input': 'required name=search_term',
    },
  },
};

export const whatsNewSeo = generateDefaultSEO(
  Constant.META.WHATS_NEW.TITLE,
  Constant.META.WHATS_NEW.DESCRIPTION,
  Constant.META.WHATS_NEW.URL,
);

export const whatsGoodSeo = generateDefaultSEO(
  Constant.META.WHATS_GOOD.TITLE,
  Constant.META.WHATS_GOOD.DESCRIPTION,
  Constant.META.WHATS_GOOD.URL,
);

export const whatsFunSeo = generateDefaultSEO(
  Constant.META.WHATS_FUN.TITLE,
  Constant.META.WHATS_FUN.DESCRIPTION,
  Constant.META.WHATS_FUN.URL,
);

export const glossarySeo = generateDefaultSEO(
  Constant.META.GLOSSARY.TITLE,
  Constant.META.GLOSSARY.DESCRIPTION,
  Constant.META.GLOSSARY.URL,
);

export const beautyAzSeo = generateDefaultSEO(
  Constant.META.BEAUTY_AZ.TITLE,
  Constant.META.BEAUTY_AZ.DESCRIPTION,
  Constant.META.BEAUTY_AZ.URL,
);

export const shortSeo = generateDefaultSEO(
  Constant.META.BEAUTY_SHORTS.TITLE,
  Constant.META.BEAUTY_SHORTS.DESCRIPTION,
  Constant.META.BEAUTY_SHORTS.URL,
);
