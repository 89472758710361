import React from 'react';
import { View, Image, Text, TextInput, Pressable } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { A } from '@expo/html-elements';

import {
  CreateResponsiveStyle,
  maxWidthContainer,
  isWeb,
} from '~global-screens';
import { Colors, Fonts, hs, vs, ms } from '@web-styles';
import { toggleModalStatus } from '~store/app/slice';
import Toast from 'react-native-toast-message';

import BaseModal from './BaseModal';

const ShareModal = ({ visible }) => {
  const dispatch = useDispatch();
  const styles = componentStyles();

  // const shareAttribute = useSelector(selectAppState('shareModalAttribute'));

  const toggle = () => {
    dispatch(toggleModalStatus('shareModal', false));
  };

  const listIconShare = [
    {
      id: 1,
      title: 'X',
      icon: require('~assets/images/social/x_share.svg'),
      urlShare: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        window.location.href,
      )}`,
    },
    {
      id: 2,
      title: 'whatsapp',
      icon: require('~assets/images/social/wa_share.svg'),
      urlShare: `https://wa.me/?text=${encodeURIComponent(
        window.location.href,
      )}`,
      dataAction: 'share/whatsapp/share',
    },
    {
      id: 3,
      title: 'telegram',
      icon: require('~assets/images/social/tele_share.svg'),
      urlShare: `https://t.me/share/url?url=${encodeURIComponent(
        window.location.href,
      )}`,
      dataAction: 'share/telegram/share',
    },
    {
      id: 4,
      title: 'facebook',
      icon: require('~assets/images/social/fb_share.svg'),
      urlShare: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href,
      )}`,
    },
    // {
    //   id: 2,
    //   title: 'instagram',
    //   icon: require('~assets/images/social/ig.svg'),
    //   urlShare: `https://www.instagram.com/?url=${encodeURIComponent(
    //     window.location.href,
    //   )}`,
    // },
    // {
    //   id: 3,
    //   title: 'pinterest',
    //   icon: require('~assets/images/social/pin.svg'),
    //   urlShare: `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    //     window.location.href,
    //   )}&media=${encodeURIComponent(shareAttribute?.media)}&description=${
    //     shareAttribute?.description
    //   }`,
    // },
  ];

  return (
    <BaseModal visible={visible} toggle={toggle}>
      <View style={styles('container')}>
        <View style={styles('headerContainer')}>
          <Text style={styles('modalShareTitle')}>SHARE</Text>
          <Pressable onPress={toggle}>
            <AntDesign
              name="closecircle"
              size={isWeb ? 24 : 20}
              color="black"
            />
          </Pressable>
        </View>
        <View style={styles('iconContainer')}>
          {listIconShare.map((e) => (
            <Pressable key={`list-icon-${e.title}`}>
              <A
                href={e.urlShare}
                target="_blank"
                data-action={e.dataAction && ''}>
                <Image source={e.icon} style={styles('iconImg')} />
              </A>
            </Pressable>
          ))}
        </View>
        <View style={styles('clipboardContainer')}>
          <TextInput
            placeholder={window.location.href ?? '<link to page>'}
            style={[Fonts('counterComment'), styles('inputContainer')]}
          />
          <Pressable
            onPress={() => {
              navigator.clipboard.writeText(window.location.href).then(() => {
                toggle();
                setTimeout(() => {
                  Toast.show({
                    type: 'success',
                    text1: 'Link successfully copied',
                  });
                }, 500);
              });
            }}
            style={styles('copyBtnContainer')}>
            <Text style={styles('modalShareCopy')}>COPY</Text>
          </Pressable>
        </View>
      </View>
    </BaseModal>
  );
};
const webStyles = {
  container: {
    width: 517,
    maxWidth: maxWidthContainer,
    backgroundColor: Colors.ICTERINE,
    padding: 22,
    rowGap: 25,
  },
  iconImg: {
    width: 65,
    height: 65,
    resizeMode: 'contain',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  inputContainer: {
    flex: 3,
    backgroundColor: Colors.WHITE,
    borderWidth: 1,
    height: 57,
    padding: 16,
    fontSize: 13,
  },
  copyBtnContainer: {
    flex: 1,
    backgroundColor: 'black',
    height: 57,
    padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  clipboardContainer: { flexDirection: 'row' },
  modalShareTitle: {
    fontSize: '18px',
    lineHeight: 21.11,
    letterSpacing: 2,
    fontWeight: 600,
    fontFamily: 'Work Sans',
  },
  modalShareCopy: {
    fontSize: '16px',
    lineHeight: 18.77,
    letterSpacing: 2,
    fontWeight: 600,
    fontFamily: 'Work Sans',
    color: Colors.WHITE,
  },
};

const mobileStyles = {
  container: {
    maxWidth: maxWidthContainer - hs(32),
    padding: ms(16),
    rowGap: vs(15),
  },
  iconImg: {
    width: hs(40),
    height: vs(40),
  },
  inputContainer: {
    height: vs(40),
    padding: ms(10),
  },
  copyBtnContainer: {
    height: vs(40),
    padding: ms(10),
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

ShareModal.propTypes = {
  visible: PropTypes.bool,
};

export default ShareModal;
